<template>
  <!--begin::Pricing-->
  <div>
    <div>
      <Buttons
        :btnCancel="false"
        :textSave="$t('GENERAL.NEW')"
        v-on:save="newCampus"
      />
    </div>
    <v-card>
      <v-card-text>
        <v-row gutters="2" justify="end">
          <v-col cols="12" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row gutters="2" justify="center">
          <v-col cols="12" md="12">
            <v-data-table :headers="headers" :items="campuses" :search="search">
              <template v-slot:[`item.name`]="{ item }">
                <router-link
                  :to="{
                    name: 'campus-edit',
                    params: { id: item.campusId },
                  }"
                >
                  {{ item.name }}
                </router-link>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import Buttons from "@/view/content/components/Buttons";

export default {
  components: {
    Buttons,
  },
  data() {
    return {
      search: "",
      itemsPerPage: 10,
      headers: [
        {
          text: this.$t("CAMPUS.NAME"),
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: this.$t("CAMPUS.CITY"),
          value: "google_place.translation",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("CAMPUS.TITLE") }]);
  },
  methods: {
    newCampus() {
      this.$router.push({ name: "campus-new" });
    },
  },
  computed: {
    ...mapGetters(["campuses"]),
  },
};
</script>
